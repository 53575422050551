<template>
    <div class="drawElectronicFence">
        <div class="map-con">
            <!-- 搜索 -->
            <el-amap-search-box class="search-box" :search-option="searchOption" :on-search-result="onSearchResult"></el-amap-search-box>
            <!-- 地图 -->
            <el-amap
                class="amap-box"
                :vid="'amap-vue'"
                :zoom="zoom"
                :center="mapCenter"
                :amap-manager="amapManager"
                :events="events"
                ref="map"
            >
                <!-- 点标记 -->
                <el-amap-marker
                    v-for="(marker, index) in markers"
                    :key="`marker${index}`"
                    :position="marker.position"
                    :icon="marker.icon"
                    :size="marker.size"
                    :events="marker.events"
                ></el-amap-marker>
                <!-- 信息窗体 -->
                <el-amap-info-window
                    :position="window.position"
                    :visible="window.visible"
                    :content="window.content"
                    :offset="[0,-20]"
                ></el-amap-info-window>
            </el-amap>
            <div class="handle-btn">
                <el-button type="primary" size="medium" @click="handleSave">
                    确定
                </el-button>
                <el-button size="medium" @click="handleClose">
                    取消
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import VueAMap from 'vue-amap';
import util from '@/utils/util';
VueAMap.initAMapApiLoader({
    key: 'b4007ecc143884e79eab2c515ede82cf',
    // eslint-disable-next-line max-len
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker', 'AMap.GeometryUtil','AMap.MouseTool'],
    v: '1.4.4',
});
const amapManager = new VueAMap.AMapManager();
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            amapManager,
            zoom: 12,
            mapCenter: [0, 0],
            events: {
                init: (o) => {
                    this.mapObj = amapManager.getMap();
                    this.initMap();
                },
            },
            mouseTool: {},
            markers: [],
            overlays:[],
            searchOption: {
                city: '全国',
                citylimit: true
            },
            window: {
                position: [0.0, 0.0],
                visible: false,
                offset: [40, 0],
            },
            mapObj: {}, // 地图对象
            handleType: 1, // 1 打点 2 绘制围栏
            echoPolygon: null, // 回显围栏图层
            address: '', // 位置地址 
            onlyPoint: false,
            // 地图定位插件对象
            mapGeolocation: {}, 
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 确定
        handleSave() {
            if (this.markers[0].position) {
                this.extr.sureCallback(this.markers[0].position,this.address);
                window.hideToast();
            } else {
                this.$message.warning('请绘制坐标点！');
            }
        },
        // 取消
        handleClose() {
            window.hideToast();
        },

        // 绘制
        draw() {
            const polygon = new AMap.Polygon({
              fillColor:'#00b0ff',
              strokeColor:'#80d8ff',
              path: [],
              //同Polygon的Option设置
            });
            return polygon;
        },
        // 搜索打点
        onSearchResult(pois) {
            let latSum = 0;
            let lngSum = 0;
            this.markers = [];
            const markerObj = {
                position: [0.0, 0.0],
                icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
                events: {
                    click: () => {
                        this.switchWindow();
                    },
                },
            }
            if (pois.length > 0) {
                const poi = pois[0];
                let {lng, lat} = poi;
                markerObj.position = [poi.lng, poi.lat];
                this.markers.push(markerObj);
                const that = this;
                let geocoder = '';
                this.mapObj.plugin(["AMap.Geocoder"],()=>{ 
                    geocoder = new AMap.Geocoder({
                        city: "全国" //城市，默认：“全国”
                    });
                });
                const lnglatXY = [poi.lng,poi.lat];
                geocoder.getAddress(lnglatXY, function (status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                        const address = result.regeocode.formattedAddress;
                        if (address) {
                            that.window.visible = false;
                            that.window.position = lnglatXY;
                            that.window.content = address;
                            that.address = address;
                            that.$nextTick(() => {
                                that.window.visible = true;
                                that.mapCenter = lnglatXY;
                            });
                        }
                    }
                });
            }
        },
        handleClick(that,e) {
            if (that.handleType === 1) {
                const addressX = e.lnglat.lng;
                const addressY = e.lnglat.lat;
                that.$nextTick(()=>{
                    that.markers = [];
                    const markerObj = {
                        position: [0.0, 0.0],
                        icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
                        events: {
                            click: () => {
                                that.switchWindow();
                            },
                        },
                    }
                    markerObj.position = [addressX,addressY];
                    that.markers.push(markerObj);
                });
                let geocoder = '';
                that.mapObj.plugin(["AMap.Geocoder"],()=>{ 
                    geocoder = new AMap.Geocoder({
                        city: "全国" //城市，默认：“全国”
                    });
                });
                const lnglatXY = [addressX,addressY];
                geocoder.getAddress(lnglatXY, function (status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                        const address = result.regeocode.formattedAddress;
                        if (address) {
                            that.window.visible = false;
                            that.window.position = lnglatXY;
                            that.window.content = address;
                            that.address = address;
                            that.$nextTick(() => {
                                that.window.visible = true;
                                that.mapCenter = lnglatXY;
                            });
                        }
                    }
                });
            }
        },
        // 打点
        addMark() {
            const that = this;
            this.mapObj.on('click', function (e) {
                that.handleClick(that,e);
            });
        },
        // 此时操作打点
        handleAddMark() {
            this.handleType = 1;
            this.mouseTool.close(false);
            this.addMark();
        },
        // 显示信息窗体
        switchWindow() {
            this.window.visible = false;
            this.window.position = this.markers[0].position;
            this.window.content = this.address;
            this.$nextTick(() => {
                this.mapCenter = this.markers[0].position;
                this.window.visible = true;
            });
        },
        // 定位当前位置
        getCurrentPosition() {
            if (!this.extr.position[0]||!this.extr.position[1]) {
                this.mapGeolocation.getCurrentPosition((status, result) => {
                    if (result && result.position) {
                        this.center = [result.position.lng, result.position.lat];
                        this.$nextTick(()=>{
                            this.markers = [];
                            const markerObj = {
                                position: [result.position.lng, result.position.lat],
                                icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
                                events: {
                                    click: () => {
                                        this.switchWindow();
                                    },
                                },
                            }
                            this.markers.push(markerObj);
                        });
                        const address = result.formattedAddress;
                        if (address) {
                            this.window.visible = false;
                            this.window.position = lnglatXY;
                            this.window.content = address;
                            this.address = address;
                            this.$nextTick(() => {
                                that.window.visible = true;
                                that.mapCenter = lnglatXY;
                            });
                        }
                        
                    }
                });
            }
        },
        // 初始化地图数据
        initMap() {
            // this.mapObj = amapManager.getMap();
            this.$nextTick(()=>{
                this.mapObj = amapManager.getMap();
                this.mapObj.plugin(["AMap.MouseTool"],()=>{ 
                    this.mouseTool = new AMap.MouseTool(this.mapObj);
                });
                // 默认操作打点
                this.addMark();
                if (this.extr.position[0]&&this.extr.position[1]) {
                    // 回显标点
                    this.markers = [];
                    const markerObj = {
                        position: [0.0, 0.0],
                        icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
                        events: {
                            click: () => {
                                this.switchWindow();
                            },
                        },
                    }
                    markerObj.position = this.extr.position;
                    this.markers.push(markerObj);
                    this.mapCenter = this.extr.position;
                    this.switchWindow();
                } else {
                    this.mapObj.plugin('AMap.Geolocation', ()=> {
                        const geolocation = new AMap.Geolocation({
                            enableHighAccuracy: true,//是否使用高精度定位，默认:true
                            timeout: 10000,          //超过10秒后停止定位，默认：无穷大
                            maximumAge: 0,           //定位结果缓存0毫秒，默认：0
                            convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
                            showButton: true,        //显示定位按钮，默认：true
                            buttonPosition: 'LB',    //定位按钮停靠位置，默认：'LB'，左下角
                            buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                            showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
                            showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
                            panToLocation: true,     //定位成功后将定位到的位置作为地图中心点，默认：true
                            zoomToAccuracy:true      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                        });
                        this.mapObj.addControl(geolocation);
                        geolocation.getCurrentPosition();
                        AMap.event.addListener(geolocation, 'complete', (data)=>{
                            this.$nextTick(()=>{
                                this.center = [data.position.lng,data.position.lat];
                                this.zoom = 12;
                            });
                        });//返回定位信息
                        AMap.event.addListener(geolocation, 'error', (err)=>{});      //返回定位出错信息
                        // function onComplete () 
                    });
                }
            });
            
        },
    },
    created() {},
    mounted() {
        if (this.extr.onlyPoint) {
            this.onlyPoint = this.extr.onlyPoint;
        }
        if (this.extr.address) {
            this.address = this.extr.address;
        }
    },
};
</script>
<style lang="stylus">
.drawElectronicFence
    height 100%
    padding .1rem
    overflow hidden
    .map-con
        height 100%
        position relative
        .search-box
            position absolute
            top .1rem
            left .1rem
            .search-btn
                background #1577D2
                color #fff
                border-radius 0 3px 3px 0
        .amap-box
            height calc(100% - .7rem)
        .handle-btn
            padding-top .2rem
            text-align center
        .handle-types
            position absolute
            bottom .1rem
            right .1rem
            background #fff
            padding .1rem
            .el-button
                padding 0 0.2rem
            .active
                background #409EFF
                border-color #409EFF
                color #fff
</style>